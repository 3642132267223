<template>
  <div class="products-detail-har common-page">
    <div class="top-page">
      <img :src="detail.bannerImg" alt="" />
    </div>
    <div class="common-card-wrap24 card-wrap-one">
      <div class="card-top">
        <div class="top">
          <div class="title">{{ detail.title }}</div>
          <div class="text">{{ detail.titleText }}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
              fill="#CDD7DE"
            />
          </svg>
        </div>
        <div class="cont">
          <h2 class="title">{{ detail.contTitle }}</h2>
          <div
            v-for="(item, index) in detail.contArr"
            :key="index"
            class="block-box"
          >
            <p class="text" v-if="item.type === 'text'">{{ item.text }}</p>
            <div v-if="item.type === 'img'" class="img-wrap">
              <img :src="item.imgUrl" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-card-wrap24 card-wrap-two">
      <div class="card-top">
        <div class="top">
          <div class="title">{{ stepData.title }}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
              fill="#CDD7DE"
            />
          </svg>
        </div>
        <div v-for="(item, index) in stepData.list" :key="index" class="cont">
          <div class="steo-wrap">
            <div class="step-title">{{ item.title }}</div>
            <p class="step-tip">{{ item.titleTip }}</p>
            <img :src="item.imgUrl" alt="" />
            <div v-if="item.type === 'collapse'" class="show-box">
              <van-collapse v-model="collapseName" accordion>
                <van-collapse-item
                  v-for="(model, eq) in item.modelList"
                  :key="eq"
                  :title="model.title"
                  :name="eq"
                >
                  <p class="text">{{ model.text }}</p>
                  <img :src="model.imgUrl" alt="" />
                  <p v-for="(tip, num) in model.tipList" :key="num" class="tip">
                    <span></span>{{ tip }}
                  </p>
                </van-collapse-item>
              </van-collapse>
            </div>
            <div v-if="item.type === 'collapse'" class="show-box"></div>
          </div>
          <div class="step-wrap"></div>
        </div>
      </div>
    </div>
    <div class="common-card-wrap24 card-wrap-tab">
      <div class="custom-vant-tab tab-wrap">
        <van-tabs type="card" v-model="tabName" swipe-threshold="3">
          <van-tab title="资料下载">
            <div class="tab-cont info-cont">
              <div class="doc-list">
                <div v-for="(item, index) in docList" :key="index" class="doc">
                  <div class="pdf-icon"></div>
                  <div class="doc-name">{{ item.docName }}</div>
                  <div class="down-icon">
                    <router-link :to="item.downUrl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.39961 12.4319V9.2001H10.5996V12.4399L11.9832 11.0561L12.8316 11.9046L10.5693 14.1673C10.2568 14.4799 9.75028 14.4799 9.43788 14.1674L7.17561 11.9046L8.02401 11.0561L9.39961 12.4319ZM10.5996 9.2001H15.6004C16.0403 9.2001 16.3996 9.55677 16.3996 9.99675V15.6035C16.3996 16.041 16.0429 16.4001 15.603 16.4001H4.39626C3.95875 16.4001 3.59961 16.0434 3.59961 15.6035V9.99675C3.59961 9.55924 3.95741 9.2001 4.39877 9.2001H9.39961V3.6001H10.5996V9.2001Z"
                          fill="white"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="产品视频">
            <div class="tab-cont">
              <div class="big-circle-box">
                <img :src="videosData.imgUrl" />
                <div class="about">
                  <div class="title">
                    {{ videosData.title }}
                  </div>
                  <div class="b-box">
                    <div class="left">
                      <div class="pdf-icon"></div>
                      <div class="size-text">{{ videosData.size }}</div>
                    </div>
                    <div class="down-icon">
                      <router-link :to="videosData.link">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.25 15.0398V11H12.75V15.0498L14.4795 13.32L15.54 14.3807L12.7121 17.209C12.3215 17.5997 11.6883 17.5997 11.2978 17.2091L8.47 14.3807L9.5305 13.32L11.25 15.0398ZM12.75 11H19.001C19.5509 11 20 11.4458 20 11.9958V19.0042C20 19.5511 19.5542 20 19.0042 20H4.99581C4.44892 20 4 19.5542 4 19.0042V11.9958C4 11.4489 4.44725 11 4.99896 11H11.25V4H12.75V11Z"
                            fill="white"
                          />
                        </svg>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="3D图纸下载">
            <div class="tab-cont">
              <div class="big-circle-box">
                <img :src="drawingData.imgUrl" />
                <div class="about">
                  <div class="title">
                    {{ drawingData.title }}
                  </div>
                  <div class="b-box">
                    <div class="tip">{{ drawingData.tip }}</div>
                  </div>
                  <div class="btn-wrap">
                    <div class="btn-text">{{ drawingData.btnText }}</div>
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="在线研讨会">
            <div class="tab-cont">
              <div class="circle-form">
                <div class="custom-form">
                  <van-form @submit="onSubmit">
                    <van-field
                      v-model="form.firstName"
                      name="firstName"
                      placeholder="姓名*"
                      :rules="[{ required: true, message: '请输入姓名' }]"
                    />
                    <van-field
                      v-model="form.email"
                      name="email"
                      placeholder="电子邮箱*"
                      :rules="[
                        { required: true, message: '请输入电子邮箱名称' },
                        {
                          pattern:
                            /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                          message: '邮箱格式错误！',
                        },
                      ]"
                    />
                    <van-field
                      v-model="form.mobile"
                      name="mobile"
                      placeholder="请输入电话*"
                      :rules="[
                        {
                          required: true,
                          message: '请填写电话号码',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^1[34578]\d{9}$/,
                          message: '电话号码格式不正确',
                        },
                      ]"
                    />
                    <van-field
                      v-model="form.company"
                      name="company"
                      placeholder="公司名称*"
                      :rules="[{ required: true, message: '请输入公司名称' }]"
                    />
                    <van-field
                      v-model="form.regionName"
                      name="regionName"
                      is-link
                      readonly
                      placeholder="国家/地区*"
                      @click="show = true"
                      :rules="[{ required: true, message: '请选择国家/地区' }]"
                    />
                    <van-popup v-model="show" round position="bottom">
                      <van-cascader
                        v-model="form.regionId"
                        title="请选择国家/地区"
                        :options="options"
                        @close="show = false"
                        @finish="onFinish"
                        active-color="#fc0"
                      />
                    </van-popup>
                    <div class="btn-wrap">
                      <van-button round block type="info" native-type="submit"
                        >提交</van-button
                      >
                    </div>
                  </van-form>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="empty"></div>
  </div>
</template>
<script>
import {
  Collapse,
  CollapseItem,
  Tab,
  Tabs,
  Form,
  Field,
  RadioGroup,
  Radio,
  Button,
  Cascader,
  Popup,
} from "vant";
export default {
  name: "ProductsDetailHar",
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Cascader.name]: Cascader,
    [Popup.name]: Popup,
  },
  data() {
    return {
      form: {
        company: "",
        surname: "",
        name: "",
        email: "",
        agree: false,
        regionName: "",
        regionId: "",
      },
      collapseName: "",
      tabName: "",
      detail: {
        bannerImg: require("../../image/products-detail-har01.png"),
        title: "浩亭 har-modular®",
        titleText:
          "ar-modular® 是适用于电路板应用的新型模块化连接解决方​​案。创建自己的产品！",
        contTitle: "创建自己的产品！",
        contArr: [
          {
            type: "text",
            text: "电子工业设备的开发工程师都了解这个问题。数据、信号和电源生命线必须从一个 PCB 连接到另一个，而大多数现有连接解决方​​案都不适合自己的项目。",
          },
          {
            type: "text",
            text: "但没有理由绝望。全新的浩亭 har-modular® PCB 连接器系统为您提供了十亿种数据、信号和电源组合的可能性。您可以在线自行配置，最低只需 1 件。这是实现创新原型开发或下一个成功设备系列的理想解决方案。",
          },
          {
            type: "img",
            imgUrl: require("../../image/products-detail-har02.png"),
          },
          {
            type: "text",
            text: "模组化和灵活性在设备开发中变得越来越重要。新设备的开发时间越来越短，而且原型开发扮演着越来越重要的角色。可用标准组件的有限选择通常会成为设备开发的障碍。 这个满足模组化、灵活性和更高设备开发速度需求的解决方案被称为 har-modular。 具有未来潜力、而且可完全由客户单独配置的插头连接。无论设备设计中的排列创新和创意如何，都不再取决于是否有合适的标准电线或变体。开发人员可以根据自己的要求调整连接器。反之亦然。每个解决方案都是可行的，并且可以从数量为1 的批量规模开始实现。这为开发人员提供了全新的视角，可以快速、经济地进行原型开发。",
          },
        ],
      },
      stepData: {
        title: "设计个性化 连接器的 3 个步骤",
        list: [
          {
            type: "collapse",
            title: "第 1 步 - 模组数量",
            titleTip:
              "在第一步中，您必须知道要连接哪些生命线以及有多少条生命线。数据、信号还是电源？还是全部三个？查看我们的 har-modular® 模组。",
            imgUrl: require("../../image/products-detail-har03.png"),
            modelList: [
              {
                title: "1.信号模组",
                text: "有两个用于信号传输的模组：",
                imgUrl: require("../../image/products-detail-har04.png"),
                tipList: [
                  "9 针，最大 2 A/针",
                  "1 个同轴插针，50 欧姆或 75 欧姆",
                ],
              },
              {
                title: "2.电源模组",
                text: "有两个用于信号传输的模组：",
                imgUrl: require("../../image/products-detail-har04.png"),
                tipList: [
                  "9 针，最大 2 A/针",
                  "1 个同轴插针，50 欧姆或 75 欧姆",
                ],
              },
              {
                title: "3.高压模组",
                text: "有两个用于信号传输的模组：",
                imgUrl: require("../../image/products-detail-har04.png"),
                tipList: [
                  "9 针，最大 2 A/针",
                  "1 个同轴插针，50 欧姆或 75 欧姆",
                ],
              },
            ],
          },
          {
            type: "tabs",
            title: "第 2 步 - 导向针",
            titleTip:
              "为了安全连接所有模组，每个 har-modular® 连接器都需要两个 导向针。根据应用的不同，可以选择塑料或金属版本。如果您询问我们的话，我们认为导向针的最佳位置是连接器端头，但也可以位于其他各个位置。",
            imgUrl: require("../../image/products-detail-har05.png"),
          },
        ],
      },
      videosData: {
        imgUrl: require("../../image/producrs-han-videos.png"),
        title: "浩亭 Han-Modular® 多米诺新一代模块化工业连接器 CN.mp4",
        size: "1MB",
        btnText: "",
        link: "",
      },
      drawingData: {
        imgUrl: require("../../image/products-han-drawing.png"),
        title: "Han-Modular多米诺模块3D图纸",
        size: "",
        tip: "图纸仅可在web端下载",
        btnText: "复制下载链接",
        link: "",
      },
      docList: [
        { docName: "har-modular Assembly Instruction DE/EN 1", downUrl: "" },
        { docName: "har-modular Assembly Instruction DE/EN 2", downUrl: "" },
      ],
      show: false,
      options: [
        {
          text: "浙江省",
          value: "330000",
          children: [{ text: "杭州市", value: "330100" }],
        },
        {
          text: "江苏省",
          value: "320000",
          children: [{ text: "南京市", value: "320100" }],
        },
      ],
      rules: {
        firstName: [{ required: true, message: "请输入姓名" }],
        lastName: [{ required: true, message: "请输入姓氏" }],
        posts: [{ required: true, message: "请选择国家/地区" }],
        city: [{ required: true, message: "请输入市" }],
        regionName: [{ required: true, message: "请选择国家/地区" }],
        company: [{ required: true, message: "请输入公司名称" }],
        email: [
          { required: true, message: "请输入电子邮箱名称" },
          {
            pattern:
              /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: "邮箱格式错误！",
          },
        ],
        mobile: [
          { required: true, message: "请填写电话号码", trigger: "blur" },
          {
            pattern: /^1[34578]\d{9}$/,
            message: "电话号码格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit(form) {
      console.log(form);
      if (!form.agree) {
        this.$notify({
          message: "请勾选政策",
          color: "#000",
          background: "#fc0",
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.form.regionName = selectedOptions
        .map((option) => option.text)
        .join("/");
    },
  },
};
</script>
<style lang="less" scoped>
.products-detail-har {
  .top-page {
    width: 100%;
    height: 160px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .common-card-wrap24 > .card-top {
    padding-bottom: 60px;
    .top {
      margin-bottom: 32px;
      padding: 0 8px;
      .title {
        margin-bottom: 8px;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      .text {
        margin-bottom: 8px;
        color: #505054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
    }
  }
  .card-wrap-one {
    background: linear-gradient(180deg, #f0f5f7 0%, #dfe4e5 87.52%);

    .cont {
      .title {
        margin-bottom: 16px;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      .img-wrap {
        margin: 8px 0 24px 0;
        padding: 16px;
        border-radius: 24px;
        background: #fff;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
          height: 370px;
          object-fit: cover;
        }
      }
      .text {
        margin-bottom: 16px;
        color: #535359;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
    }
  }
  .card-wrap-two {
    position: relative;
    top: -40px;
    background: linear-gradient(180deg, #f0f5f7 0%, #dfe4e5 87.52%);
    .step-wrap {
      margin-bottom: 32px;
    }
    .step-title {
      margin-bottom: 16px;
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 28.8px */
    }
    .step-tip {
      margin-bottom: 24px;
      color: #535359;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
    img {
      margin-bottom: 24px;
      display: block;
      width: 100%;
      height: 190px;
      object-fit: cover;
      border-radius: 24px;
    }
    .show-box {
      .text {
        margin-bottom: 16px;
        color: #535359;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
      .tip {
        display: flex;
        align-items: center;
        color: #212027;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 22.5px */
        span {
          margin: 0 6px 0 4px;
          width: 4px;
          height: 4px;
          background: @theme-color;
        }
      }
    }
  }
  .card-wrap-tab {
    position: relative;
    top: -80px;
    .tab-cont {
      padding: 32px 0 0 0;
    }
    .about {
      padding: 16px;
    }
    .title {
      margin-bottom: 8px;
      color: #212027;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
    }
    .b-box {
      display: flex;
      justify-content: space-between;
    }
    .left {
      display: flex;
      align-items: center;
    }
    .pdf-icon {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background: url("../../image/pdf-icon.png") no-repeat center center /
        cover;
    }
    .size-text,
    .tip {
      color: #a9a9ac;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 14px */
    }
    .tip {
      margin-bottom: 26px;
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
      .btn-text {
        padding: 12px 24px;
        border-radius: 40px;
        background: @theme-color;
        color: #212027;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }
    }
    .down-icon {
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: @theme-color;
        border-radius: 50%;
      }
    }
    .big-circle-box {
      width: 327px;
      border-radius: 24px;
      overflow: hidden;
      background: #fff;
      box-shadow: 0px 0px 16px 0px #d7d9dc;
      img {
        display: flex;
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
    }
    .circle-form {
      border-radius: 24px;
      padding: 40px 32px;
      background: @theme-color;
      .btn-wrap {
        bottom: 40px;
      }
      .custom-form {
        padding-bottom: 80px;
      }
    }
    .doc-list {
      .doc {
        display: flex;
        margin-bottom: 16px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #fff;
        background: #fff;

        /* 卡片投影 */
        box-shadow: 0px 0px 16px 0px #d7d9dc;
        .pdf-icon {
          margin-right: 8px;
          width: 38px;
          height: 38px;
          background: url("../../image/pdf-icon.png") no-repeat center center /
            cover;
        }
        .doc-name {
          color: #212027;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
        }
        .down-icon {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background: @theme-color;
            border-radius: 50%;
          }
        }
      }
    }
    .card-wrap-form {
      position: relative;
      top: -120px;
      background: @theme-color;
      .custom-form {
        padding: 0;
      }
      .title {
        margin-bottom: 16px;
        color: #212027;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 48px */
      }
      .text {
        margin-bottom: 16px;
        color: #505054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
      .custom-form {
        .btn-wrap {
          position: relative;
          bottom: 0;
          padding: 0;
        }
      }
    }
  }
  .empty{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background: #fff;
  }
}
</style>
<style lang="less">
.products-detail-har {
  position: relative;
  .van-collapse {
    border-radius: 16px;
    overflow: hidden;
    .van-collapse-item__title {
      padding: 16px;
    }
    .van-cell__title {
      color: #212027;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 21.6px */
    }
  }
  .card-wrap-tab {
    .van-tabs--card > .van-tabs__wrap {
      padding: 0;
      justify-content: center;
    }
  }
}
</style>
